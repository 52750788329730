import { useEffect } from 'react';
import { tools } from './CornerStoneDicomViewer';

export const useKeyPress = (callback: (T?: any) => void, keys: KeyboardKey[]) => {
  const onKeyDown = (event: KeyboardEvent) => {
  const anyKeyPressed = keys.find((key) => event.key.toLowerCase() === key);
  // console.log(event.key, anyKeyPressed, 'anyKeyPressed');

    if (anyKeyPressed !== undefined) {
      event.preventDefault();
      callback(keyToolPair[anyKeyPressed]);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);
};

export enum KeyboardKey {
  // escape = 'Escape',
  // enter = 'Enter',
  P = 'p',
  L = 'l',
  W = 'w',
  M = 'm',
  A = 'a',
  R = 'r',
  E = 'e',
  T = 't',
  D = 'p',
}

const keyToolPair = {
  'p' : 'Pan',
  'l' : 'Length',
  'w' : 'Wwwc',
  'm' : 'Magnify',
  'a' : 'Angle',
  'r' : 'RectangleRoi',
  'e' : 'EllipticalRoi',
  't' : 'Rotate',
  'd' : 'Probe',
}