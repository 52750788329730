import React, { useContext, useState, useEffect } from 'react'
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import NixImage from '../../../../components/NixImage';
import ExamFooter from '../ExamFooter/ExamFooter';
import ExamSidebar from '../ExamSidebar/ExamSidebar';
import { ExamData, ExamDetails, MappingData, ResponsesData, SelectedQuestion } from '../context';


import "./ExamHome.css";
import ExamQuestionBody from '../ExamQuestionBody/ExamQuestionBody.index';
import { IOptionsData } from '../ExamQuestionBody/ExamQuestion/ExamQuestion.options';
import LiveExam from '../../../../Services/liveexam.services';
import toast from 'react-hot-toast';


interface ISetUserAnswersData {
    markedForReview: boolean
    questionId: string
    optionsData: IOptionsData[] | undefined
}

export default function ExamHome() {

    const examData: any = useContext(ExamData);

    const examDetails: any = useContext(ExamDetails);
    const { selectedQuestionId, setSelectedQuestionId } = useContext<any>(SelectedQuestion);
    const { responsesData, setResponsesData } = useContext<any>(ResponsesData);



    const [userAnswersData, setUserAnswersData] = useState<ISetUserAnswersData>();

    const handleSectionChange = (sectionId: string) => {
        const sectionDetails = responsesData.questionArr.find((arr: any) => arr.section === sectionId);
        setSelectedQuestionId({
            ...selectedQuestionId,
            section: sectionId,
            question: sectionDetails.questions[0]
        })
    }

    const getUserSavedAnswer = async () => {
        if(!selectedQuestionId) return;
        await LiveExam.getUserSavedAnswerByQuestionId(selectedQuestionId.question).then((res) => {
            if (res.status === 200) {
                if (res.data.questionId) {
                    setUserAnswersData(res.data);
                }
                else {
                    setUserAnswersData({
                        markedForReview: false,
                        questionId: selectedQuestionId.question,
                        optionsData: []
                    })
                }
            }
        }).catch((err) => {
            console.log(err);
            setUserAnswersData({
                markedForReview: false,
                questionId: selectedQuestionId.question,
                optionsData: []
            })
        })
    }


    const handleAnswersDataChange = async (e: any) => {
        const update: any = { ...userAnswersData, questionId: e.questionId, optionsData: e.optionsData }
        if (update.optionsData.find((option: any) => option.flag === true) && update.markedForReview === false) {
            update.markedForReview = true
            await handleSaveDataMarkForReview(update)
        }
        setUserAnswersData(update)
    }

    const handleMarkedForReviewChange = async (e: any) => {
        if (userAnswersData?.markedForReview != e && userAnswersData?.questionId) {
            const update: any = { ...userAnswersData, markedForReview: e }
            // set state 
            setUserAnswersData(update)
            // update api call

            await handleSaveDataMarkForReview(update);
            // get new data
            await getUserSavedAnswer()
        }
    }


    // save with object

    const handleSaveDataMarkForReview = async (update: ISetUserAnswersData) => {
        if (update) {
            let tobe_saved_optionData: any = update.optionsData
            if (update.optionsData) {
                tobe_saved_optionData = update?.optionsData.filter((ans: any) => ans.value)
            }
            return await LiveExam.updatedResponseData({ ...update, optionsData: tobe_saved_optionData }).then(res => {
                if (res.status === 200) {
                    toast.success("Response Saved")
                    return true;

                }
            }).catch((e: any) => {
                console.error(e)
                toast.error("Unable to Save Response")
                return false
            })
        }
        else {
            // toast.error("No Response available")
            return false;
        }
    }


    // normal saving with state
    const handleSaveData = async () => {
        if (userAnswersData && userAnswersData.questionId) {
            let tobe_saved_optionData: any = userAnswersData.optionsData
            if (userAnswersData.optionsData) {
                tobe_saved_optionData = userAnswersData?.optionsData.filter((ans: any) => ans.value)
            }
            return await LiveExam.updatedResponseData({ ...userAnswersData, optionsData: tobe_saved_optionData }).then(res => {
                if (res.status === 200) {
                    toast.success("Response Saved")
                    return true;

                }
            }).catch((e: any) => {
                console.error(e)
                toast.error("Unable to Save Response")
                return false
            })
        }
        else {
            toast.error("No Response available")
            return false;
        }
    }

    const reloadUserAnswers = async () => {
        await getUserSavedAnswer();
    }


    useEffect(() => {
        getUserSavedAnswer();
    }, [selectedQuestionId])


    return (
        <>
            <div className="main">
                <Row>
                    <Col md={12} className="me-0 pe-0">
                        <div className='dcm-section'>
                            {examData?.data && examData.data.length > 0 &&
                                <div className='d-flex justify-content-between align-items-center'>
                                    <div className='d-flex justify-content-start align-items-center'>
                                        {examData.data.map((data: any) => {
                                            return (
                                                <span onClick={() => handleSectionChange(data._id)} className="dcm-link-cursor text-uppercase my-3">
                                                    {
                                                        selectedQuestionId && selectedQuestionId.section === data._id ?
                                                            <span className='dcm-selected-section m-3'>{data.sectionName} </span> :
                                                            <span className='m-3'>{data.sectionName}</span >
                                                    }
                                                </span>
                                            )
                                        })}
                                    </div>
                                    <div className='fw-bold fs-primary me-3'>
                                        Total Number of Sections: {examData.data.length}
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="dcm-exam-body">
                            <ExamQuestionBody
                                onUserResponseChange={handleAnswersDataChange}
                                userAnswers={userAnswersData && userAnswersData.optionsData ? userAnswersData.optionsData : []}
                            />
                        </div>
                        {/* buttons  */}
                        <ExamFooter
                            reloadUserAnswers={reloadUserAnswers}
                            handleMarkedForReviewChange={handleMarkedForReviewChange}
                            onSaveClicked={handleSaveData}
                            defaultMarkforReviewState={userAnswersData && userAnswersData.markedForReview ? userAnswersData.markedForReview : false}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}