import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';

interface IExamQuestionStatement {
    statement: any
}

export default function ExamQuestionStatement(props: IExamQuestionStatement) {

    useEffect(() => {
        if (props.statement && props.statement.question) {
            const element = document.getElementById("statement-text-question");
            if (element) {
                element.innerHTML = props.statement.question
            }
        }
    }, [props.statement])

    return (
        <>
            <div className='ms-2 px-2'>
                <Card className='dcm-exam-list-card'>
                    <div className="mx-2">
                        <div className='text-secondary h5 pt-3'>
                            Question:
                        </div>
                        <div className='pb-3'>
                            <div id="statement-text-question"></div>
                        </div>
                    </div>
                </Card>
            </div>
        </>
    )
}