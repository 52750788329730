import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { Alert, Button, Col, Row, Table } from 'react-bootstrap'
import LiveExam from '../../Services/liveexam.services'
import CustomModal from './Modal'
import { ExamData } from '../../view/Private/Exam/context'

interface IEndExam {
    show: any,
    setShow: any,
    onSuccess?: any,
}

interface ISectionQuestionStatus {
    markedForReview: any[]
    answered: any[]
    markedandAnswered: any[]
    sectionName: string
    sectionId: string
    allQuestions?: any[]
}

const EndExamConfirmation = ({ show, setShow, onSuccess }: IEndExam) => {

    const [questionStatus, setQuestionStatus] = useState<any>({
        markedForReview: [],
        answered: [],
        markedandAnswered: []
    })
    const examData: any = useContext(ExamData);


    const [sectionQuestionStatus, setSectionQuestionStatus] = useState<ISectionQuestionStatus[]>()

    const [totalQuestionCount, setTotalQuestionCount] = useState<number>(0)

    const [sectionData, setSectionData] = useState<any>([])

    const checkifAnswered = (optionData: any) => {
        if (optionData.length <= 0) {
            return false
        }
        return optionData.find((data: any) => data.value) ? true : false
    }

    const getAllUserResponse = async () => {
        await LiveExam.getResponses().then((res) => {
            if (res.status === 200) {
                let total = 0
                for (let section of res.data.data.questionArr) {
                    total = total + section.questions.length
                }
                setTotalQuestionCount(total);

            }
        }).catch((err) => {
            console.log(err);
        })

        await LiveExam.getAllUserEncryptedOptions().then((res) => {
            if (res.status === 200) {
                let markedForReview: any[] = [];
                let answered: any[] = [];
                let markedandAnswered: any[] = [];
                // let visited :any[]= [];
                for (let enc_option of res.data.data) {
                    if (enc_option.markedForReview === true) {
                        if (enc_option.selectedOption && enc_option.selectedOption.length > 0 && checkifAnswered(enc_option.selectedOption)) {
                            markedandAnswered.push(enc_option);
                        }
                        else {
                            // if no option exist but with mark
                            markedForReview.push(enc_option)
                        }
                    }
                    else {
                        if (checkifAnswered(enc_option.selectedOption)) {
                            answered.push(enc_option);
                        }
                        else {
                            // visited.push(enc_option);
                        }
                    }
                }


                setQuestionStatus({
                    markedForReview,
                    answered,
                    markedandAnswered
                    // visited
                })


                let section_wise_response: ISectionQuestionStatus[] = []

                for (let section of examData.data) {
                    let responses: ISectionQuestionStatus = {
                        markedForReview: [],
                        answered: [],
                        markedandAnswered: [],
                        sectionName: section.sectionName,
                        sectionId: section._id,
                        allQuestions: section.questionData
                    }
                    for (let enc_response of res.data.data) {
                        let questionExistInSection = section.questionData.find((question_data: any) => question_data._id === enc_response.questionId);
                        if (!questionExistInSection) {
                            continue;
                        }
                        if (enc_response.markedForReview === true) {
                            if (enc_response.selectedOption && enc_response.selectedOption.length > 0 && checkifAnswered(enc_response.selectedOption)) {
                                responses.markedandAnswered.push(enc_response);
                            }
                            else {
                                // if no option exist but with mark
                                responses.markedForReview.push(enc_response)
                            }
                        }
                        else {
                            if (checkifAnswered(enc_response.selectedOption)) {
                                responses.answered.push(enc_response);
                            }
                            else {
                                // visited.push(enc_option);
                            }
                        }
                    }
                    section_wise_response.push(responses)
                }

                setSectionData(section_wise_response)

            }
        }).catch((err) => {
            console.log(err);
        })


    }




    useEffect(() => {
        getAllUserResponse();
    }, [show])

    return (
        <>
            <CustomModal
                displayModal={show}
                onClose={() => setShow(false)}
                size="xl"
                removeClose={true}
                modalBody={
                    <>
                        <h5 className='fw-bold'>End Exam Confirmation</h5>
                        <Alert variant='warning'> <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" />Are you sure you want to end your exam! <b>Exam once Submitted can't be resumed/restarted</b>
                        </Alert>
                        <div className="mb-2">
                            <Row>
                                <Col>
                                    <div className='fw-bold'>
                                        <span className='text-secondary'>Total Questions: </span> {totalQuestionCount}
                                    </div>
                                </Col>
                                <Col>
                                    <div className='fw-bold'>
                                        <span className='text-secondary'>Marked For Review: </span> {questionStatus.markedForReview.length}
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className='fw-bold'>
                                        <span className='text-secondary'>Answered: </span> {questionStatus.answered.length}
                                    </div>
                                </Col>
                                <Col>
                                    <div className='fw-bold'>
                                        <span className='text-secondary'>Marked and Answered: </span> {questionStatus.markedandAnswered.length}
                                    </div>
                                </Col>
                            </Row>




                            <div className='fw-bold'>
                                <span className='text-secondary'>Not Answered: </span> {totalQuestionCount - (questionStatus.markedForReview.length + questionStatus.answered.length + questionStatus.markedandAnswered.length)}
                            </div>

                        </div>

                        <hr />
                        <div className='mt-3'>
                            <h5 className='fw-bold'>Section Wise Summary</h5>
                            <Table responsive striped bordered>
                                <thead>
                                    <tr>
                                        <th  className='text-center'>Name</th>
                                        <th className='text-center'>Total Questions</th>
                                        <th  className='text-center'>Marked For Review</th>
                                        <th  className='text-center'>Answered</th>
                                        <th  className='text-center'>Marked and Answered</th>
                                        <th  className='text-center'>Not Answered</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sectionData && sectionData.length > 0 ?
                                            sectionData.map((section: any) => {
                                                return (
                                                    <tr>
                                                        <td className='fw-bold'>{section?.sectionName}</td>
                                                        <td className='text-center text-primary fw-bold'>{section?.allQuestions?.length}</td>
                                                        <td className='text-center text-warning fw-bold'>{section?.markedForReview?.length}</td>
                                                        <td  className='text-center text-success fw-bold'>{section?.answered?.length}</td>
                                                        <td  className='text-center fw-bold' style={{color: "rgb(131, 32, 243)"}}>{section?.markedandAnswered?.length}</td>
                                                        <td  className='text-center fw-bold text-danger'>{section?.allQuestions?.length - (section?.markedForReview?.length + section?.answered?.length + section?.markedandAnswered?.length)}</td>
                                                    </tr>
                                                )
                                            }) : null
                                    }
                                </tbody>
                            </Table>
                        </div>
                        <div className="d-flex justify-content-end">
                            <Button
                                variant="secondary"
                                onClick={() => setShow(false)}
                                className="me-2"
                            >
                                Go, back
                            </Button>
                            <Button
                                variant="danger"
                                onClick={onSuccess}
                            >
                                Submit exam
                            </Button>
                        </div>
                    </>
                }
            />

        </>
    )
}

export default EndExamConfirmation