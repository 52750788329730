import React, { useContext, useState } from 'react'
import {ExamData, SelectedQuestion} from '../context'
import "./sidebar.css";

interface IQuestion{
    queType:string,
    queNumber:number,
    onClick: any
}
export default function Question({queType , queNumber, onClick}:IQuestion) {
    
    const [questionValue, setQuestionValue] = useState(false);

    const handleTarget = () => {
        setQuestionValue(true);
    }

    return (
        <>
            <li className={queType} onClick = {onClick}>
                <div className="d-flex justify-content-center xcn-button" style={{cursor: "pointer"}}  onClick={handleTarget}> 
                    <span className={queType === "hexagon" ? "clrRose-hexagon" : "clrRose"}>{queNumber}</span>
                </div>
            </li>
        </>
    )
}
