import React, { useState, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import Ongoing from './Ongoing'
import Past from './Past'
import Upcoming from './Upcoming'
import makeRequest from '../../../../api/makeRequest'
import { ExamService } from '../../../../Services/examServices'
import { IExam } from '../../../../interfaces/IExam'


interface ExamType {
    pending: any[],
    completed: any[],
    upcomming: any[],
}

export default function ListExams() {
    const [ExamData, setExamData] = useState<ExamType>({
        pending: [],
        completed: [],
        upcomming: [],
    })

    //here data will come through api
    const getAllExam = async () => {
        await ExamService.getAllExam()
            .then((res) => {
                if (res.status === 200) {
                    setExamData(res.data.data.examData);
                }
            })
            .catch((err) => {
                console.error(err);
            })
    }

    useEffect(() => {
        getAllExam();
    }, [])

    return (
        <>
            <Container>
                <div className="my-4">
                    <Ongoing exams={ExamData.pending} />
                </div>

                {/* <div className="mt-4">
                    <Upcoming exams={ExamData.upcomming} />
                </div>


                <div className="mt-4">
                    <Past exams={ExamData.completed} />
                </div> */}
            </Container>
        </>
    )
}