import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import DynamicLabel from '../../../../components/Labels/Dynamic.label';
import { createDateFormatWithTime, getTimeFromMins } from '../../../../helpers/date.helper';
import { ExamService } from '../../../../Services/examServices';
import { IExam } from '../../../../interfaces/IExam'
import '../../../../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faDotCircle, faPlay, faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import toast from 'react-hot-toast';
import LiveExam from '../../../../Services/liveexam.services';
import { errorResponseHelper } from '../../../../helpers/request.helper';
import BooleanLabel from '../../../../components/Labels/Boolean.label';
import moment from 'moment';
import prettyMilliseconds from 'pretty-ms';

interface IOnging {
    exams: IExam[]
}

export default function Ongoing(props: IOnging) {
    const navigate = useNavigate();
    const [startExamId, setStartExamId] = useState('')


    const generateTokenForLiveExam = async (examId:string, mappingId:string) => {
        await LiveExam.generateTokenForLiveExam(examId, mappingId).then((res) => {
            if (res.status === 200) {
                sessionStorage.setItem("liveToken", res.data.data.token);
                toast.success("Exam CountDown Has begun");
                navigate('/live/exam-live', { state: { examId: examId, mappingId: mappingId } });
            }
        }).catch(e => {
            errorResponseHelper(e)
        })
    }
    
    const startExam = async (data:any , id2:any) => {

        await ExamService.startExamId(data)
            .then(async (res) => {
                if(res.status === 200){
                    if(res.data){
                        setStartExamId(res.data._id)
                        if(res.data.examStart === true){
                            await generateTokenForLiveExam(data,id2)
                        }
                        else{
                            navigate('/exam/instructions' , {state:{examId:data , mappingId:id2}})
                        }
                    }
                }
            })
            .catch((err) => {
                toast.error(err.response.data)
                console.error(err);
            })
    }

    return (
        <Row>
            <Col>
                <Card className="dcm-exam-list-card">
                    <Card.Header>
                        <div className='d-flex justify-content-start align-items-center'>
                        Exams Live 
                        <div style={{fontSize: "11px"}} className="ms-1">
                            <FontAwesomeIcon icon={faCircle} className="text-success "/>
                        </div>
                        </div>
                        

                    </Card.Header>
                    <Card.Body>
                        <Table borderless className="">
                            <thead>
                                <tr>
                                    <th>
                                        Name
                                    </th>
                                    <th>
                                        Start Time
                                    </th>
                                    <th>
                                        End Time
                                    </th>
                                    <th>
                                        Subject
                                    </th>
                                    <th>
                                        Duration
                                    </th>
                                    <th>
                                        Rem. Time
                                    </th>
                                    <th>
                                        
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.exams.map((data: any, index) => {
                                      if(!data.exam) {
                                        return (
                                            <></>
                                        )
                                    }
                                    if (data) {
                                        return (
                                            <tr key={index} className="dcm-table-row-card"  >
                                                <td>
                                                    <div>
                                                        <span className="fw-bold fs-12">
                                                            {data?.exam?.examName}
                                                        </span>
                                                           
                                                            {/* {
                                                                data.exam.tags.map((tag: string) => {
                                                                    return (
                                                                        <DynamicLabel
                                                                            options={[{
                                                                                label: tag,
                                                                                value: tag,
                                                                                color: "success"
                                                                            }]}
                                                                            value={tag}
                                                                        />
                                                                    )
                                                                })
                                                            } */}
                                                    </div>
                                                </td>

                                                <td className='fs-12'>
                                                    {createDateFormatWithTime(data.exam.userAllowedStartTime)}
                                                </td>
                                                <td className='fs-12'>
                                                    {createDateFormatWithTime(data.exam.userAllowedEndTime)}
                                                </td>
                                                <td className='fs-12'>
                                                    {data.exam.subject}
                                                </td>
                                                <td className='fs-12 text-primary fw-bold'>
                                                    <>
                                                    {data.exam ? String(moment(data.exam.userAllowedEndTime).diff(moment(data.exam.userAllowedStartTime), "minutes")) + " mins" : "--"}
                                                    </>
                                                </td>
                                                <td className="text-secondary fs-12 fw-bold">
                                                    { data.remainingTime <=-1 ? "Yet to start" : prettyMilliseconds(data.remainingTime*1000)}
                                                </td>
                                                <td>
                                                    <BooleanLabel
                                                        value = {data.completionStatus}
                                                        label ={data.completionStatus ? "Completed" : "Pending"}
                                                    />
                                                    
                                                </td>
                                                <td>
                                                    {
                                                        data.completionStatus ? <Button disabled={true} size="sm" variant="secondary">
                                                            Read Instructions and Start Exam
                                                        </Button> : 
                                                    <Button className="fs-10"  variant="success" onClick={() => { startExam(data.exam._id ,data._id ) }} size="sm">Read Instructions & Start Exam <FontAwesomeIcon icon={faPlayCircle}/> </Button>

                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                })}
                            </tbody>
                        </Table>

                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}