const url = {
    login: "/auth/userLogin",
    logout: "/auth/userLogout",
    exam:{
        getAllExam:"/exam/getAllExam",
        getExamById:"/exam/getExamById",
        startExamId : "/exam/startExam",
        getQuestionDataByDataid: "/exam/getQuestionDataByDataid",
        getUserInfoWithExams: "/exam/getUserInfoWithExams"
    },
    liveExam:{
        getAllQuestionIds:"/liveexam/getAllQuestionIds",
        getUserAllowedStartTime : "/liveexam/getUserAllowedStartTime",
        getLiveExamToken: "/liveExam/getLiveExamToken",
        getExamDetails : '/liveExam/getExamDetails',
        getExamData: "/liveExam/getExamData",
        getResponses: "/liveExam/getResponses",
        getQuestionData: "/liveExam/getQuestionDataByDataid",
        getS3url: "/liveExam/getS3url",
        getUserInfo: "/liveExam/getUserInfo",
        markForReview:"/liveExam/markForReview",
        clearResponse: "/liveExam/clearResponse",
        getMarkForReview: "/liveExam/getMarkedReview",
        updatedResponseData: "/liveExam/saveresponse",
        getUserSavedAnswersByQuestionId: "/liveExam/getusersavedanswersbyquestionid",
        setExamStatusCompleted: "/liveExam/setExamStatusCompleted",
        getExamInstructions: "/liveExam/getExamInstructions",
        getAllUserEncryptedOptions: "/liveExam/getAllUserEncryptedOptions",
        getInstructions:"/liveExam/getLiveExamInstructions",
        checkUserRemainingTime: "/liveExam/checkUserRemainingTime",
        getQuestionThumbnails: "/liveExam/thumbnails"
        
    },
    public: {
        getAllLiveExams: "/public/getAllLiveExams",
        getQuestionDataByExamId: "/public/getQuestionDataByExamId"
    },

    server: {
        checkLocalServer: "/check"
    }
};

export default url;  