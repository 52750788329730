import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Alert, Button, Card, Image } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Navigate, useNavigate } from 'react-router-dom';
import { ExamService } from '../../Services/examServices';
import LiveExam from '../../Services/liveexam.services';


const UserInfoModal = () => {


    const [userInfo, setUserInfo] = useState<any>();



    const getUserInfoExams = async () => {
        await LiveExam.getUserInfo().then(res=>{
            if(res.status === 200){
                setUserInfo(res.data.data)
            }
        }).catch((err) => {
            console.log(err);

        })
    }

    function createDateFormat(date: Date) {
        return moment(date).format("Do MMM YYYY");
    }


    useEffect(() => {
        getUserInfoExams();
    }, [])

    return (
        <div>
                    {userInfo && userInfo.student &&
                            <div className='text-white' >

                                <div className='text-left'>
                                    <div>
                                        <img src={userInfo.student.image} height="40px"/>
                                    </div>
                                    <div><span>Name:</span> <span className="fw-bold">{userInfo.student.name + " " + userInfo.student.last_name}</span> </div>
                                    <div><span>Email:</span> <span className="fw-bold">{userInfo.student.email}</span></div>
                                    <div><span>Date of Birth:</span> <span className='fw-bold'>{createDateFormat(userInfo.student.dob)}</span> </div>
                                    <div><span>Reg Number:</span> <span className='fw-bold'>{userInfo.student.registrationNumber || "---"}</span> </div>
                                </div>

                            </div>
                    }
        </div>
    )
}

export default UserInfoModal