import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { SelectedQuestion } from '../../view/Private/Exam/context';

interface IChoiceOption {
    label: any
    count: number
    onChange: any
    optionId: string
    defaultValue?: any
}

export default function ChoiceOption(props: IChoiceOption) {


    const [isSelected, setIsSelected] = useState<boolean>(props.defaultValue && props.defaultValue.value ? props.defaultValue.value : false);
    const [flagBoolean, setFLagBoolean] = useState<boolean>(false);

    const handleChange = () => {
        setIsSelected(!isSelected);
    }

    useEffect(()=>{
        props.onChange({
            optionId: props.optionId,
            value: isSelected
        });
    }, [isSelected, flagBoolean])


    useEffect(()=>{
        if(props.defaultValue && props.defaultValue.value === true){
            setIsSelected(true)
        }
        else{
            setIsSelected(false)
        }
    },[props.defaultValue])

    return (
        <>
            <Form.Group>
                <Form.Check
                    type="radio"
                    label={<div className="h5 mb-2">
                        <span className="fw-bold">
                            {String((props.count) + ".")}

                            {/* {String((props.count + 9).toString(36).toUpperCase() + ".")} */}
                        </span>
                        <span className='ms-1'>
                            {props.label}
                        </span>
                    </div>}
                    onClick={handleChange}
                    checked = {isSelected}
                />
            </Form.Group>

        </>
    )
}