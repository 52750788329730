import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Row, Col, Button, Form, Navbar, Container, Nav, Image } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import Auth from '../../auth/auth';
import fingerPrint from '../../helpers/fingerprintjs';
import { AuthService } from '../../Services/authServices';
import "./dashboardnav.css";
import logo from "../../assets/icons/x-rays.png";
import toast from 'react-hot-toast';

export default function DashboardNav() {
    const navigate = useNavigate();

    const handelClick = async () => {
        await AuthService.logout(({
            devInfo: await fingerPrint()
        }))
            .then(async (res) => {
                if (res.status === 200) {
                    await Auth.signout();
                    localStorage.setItem("authKey", res.data.data.token)
                    toast.success("loged out")
                }
                navigate('/login')
            })
            .catch((err) => {
                if (err.response.status === 403) {
                    console.error(err);
                }
            })
    };
    return (
        <Navbar bg="dcm-white" expand="lg" className="dcm-navbar">
            <Container>
                <Navbar.Brand><Image src={logo} className="dcm-logo-image"/> <span> NIXDICOM</span></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link>Dashboard</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <span className="dcm-link-cursor fw-bold" onClick={handelClick}>Logout <FontAwesomeIcon icon={faRightFromBracket} /></span>
            </Container>
        </Navbar>
    )
}