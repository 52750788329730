import React, { useEffect, useState } from 'react';
import { useIndexedDB } from 'react-indexed-db';
import { Outlet } from 'react-router-dom';
import getCache from '../../cache/Get.cache';
import decipherData from '../../helpers/Crypt/decrypt';
import { unZipFiles } from '../../helpers/fs.helper';
import { ExamService } from '../../Services/examServices';
import PublicService from '../../Services/public.service';



export default function PrivateRoutes() {

    // const { add, getByID, getByIndex, getAll, deleteRecord } = useIndexedDB("dicom");



    // useEffect(() => {
    //     getAll().then((rows:any) => {
    //       setDicomData(rows);
    //     });

    //     return () => {};
    //   });


    // const storeDataToIndexDb = async () => {
    //     const liveExamsReq = await PublicService.getAllLiveExams();
    //     const liveExams = liveExamsReq.data.map((data: any) => {
    //         return data._id
    //     })



    //     if (!liveExams || liveExams.length === 0) return true;
    //     for (const exam of liveExams) {
    //         const examData = await PublicService.getQuestionDataByExamId(exam);
    //         if (examData.data && examData.data.data && examData.data.data.length > 0) {
    //             for (const link of examData.data.data) {
    //                 const data = await getByIndex("_id", link);
    //                 if (!data) {

    //                     await ExamService.getQuestionDataByDataid(link).then(async (res) => {
    //                         if (res.status === 200) {
    //                             const enc_data = await getCache(examData.data.s3Url + "/" + link);
    //                             const dec_data = await decipherData(enc_data, res.data.data.secretKey)
    //                             const unzipped_data = await unZipFiles(dec_data)
    //                             if(unzipped_data.length > 0 ){
    //                                 const added_data = await add({
    //                                     _id: link,
    //                                     data: unzipped_data
    //                                 });
    //                             }
                                
    //                         }
    //                     });
    //                 }
    //             }

    //         }
    //     }
    // }

    useEffect(() => {
        // storeDataToIndexDb();
    }, [])

    return (
        <>
            <Outlet />
        </>
    )
}