import React from 'react';
import DashboardNav from '../../../components/Navbar/DashboardNav';
import ListExams from './ListExams/ListExams';

export default function ExamDashboard() {
    return (
        <div>
            
            <DashboardNav/>
            <div className="mt-3">
                <ListExams />
            </div>
            
            {/* <Row>
                <Col md={4}>
                    <div className="card">
                        <h4>Available Exams </h4>
                        <Form.Select aria-label="Default select example">
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </Form.Select>
                        <Button className='m-3 w-50' variant="primary">Start Exam</Button>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="card">
                        <h4>Past Exams </h4>
                    </div>
                </Col>
                <Col md={4}>
                    <div className="card">
                        <h4>Future Exams </h4>
                    </div>
                </Col>
            </Row> */}
        </div>
    )
}