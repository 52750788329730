import makeRequest from "../api/makeRequest";
import url from "../api/urls";
import { RequstMethods } from "../api/requestMethode";

export class ExamService {
    static async getAllExam() {
        return await makeRequest(url.exam.getAllExam , RequstMethods.GET )
    }
    static async getExamById(_id:string) {
        return await makeRequest(url.exam.getExamById+'/'+_id , RequstMethods.GET)
    }
    static async startExamId(_id:string) {
        return await makeRequest(url.exam.startExamId+'/'+_id , RequstMethods.POST)
    }
    static async getAllQuestionIds(_id:string) {
        return await makeRequest(url.liveExam.getAllQuestionIds+'/'+_id , RequstMethods.GET)
    }
    static async getQuestionDataFromS3(S3Url:string ,_id:string) {
        return await makeRequest(S3Url+'/'+_id , RequstMethods.GET)
    }
    static async getUserAllowedStartTime(_id:string) {
        return await makeRequest(url.liveExam.getUserAllowedStartTime+'/'+_id , RequstMethods.GET)
    }

    static async getQuestionDataByDataid(dataId: string){
        return await makeRequest(url.exam.getQuestionDataByDataid+"/"+dataId+"?timestamp="+new Date().getTime(), RequstMethods.GET);
    }

    static async getUserInfoWithExams() {
        return await makeRequest(url.exam.getUserInfoWithExams, RequstMethods.GET);
    }

    static async getInstructions(examId: string){
        return await makeRequest(url.liveExam.getExamInstructions+"/"+examId, RequstMethods.GET)
    }

}