import moment from 'moment';

export function createDateForInput(date?: Date){
    return moment(date).format("YYYY-MM-D");
}

export function createAgeFormat(date: Date){
    return moment().diff(moment(date, "DD MMM YYYY"), 'years');
}

export function createDateFormat(date: Date){
    return moment(date).format("Do MMM YYYY");
}

export function createDateFormatWithTime(date: Date){
    return moment(date).format("Do MMM YYYY hh:mm a");
}

export function getTimeFromMins(mins:number) {
    if (mins >= 24 * 60 || mins < 0) {
        throw new RangeError("Valid input should be greater than or equal to 0 and less than 1440.");
    }
    var h = mins / 60 | 0,
        m = mins % 60 | 0;
    return moment.utc().hours(h).minutes(m).format("hh:mm");
}

export function secondToCountDown (seconds: number){
    return moment.utc((seconds)*1000).format('HH:mm:ss')
}

export function dateDiffInMin(dateStart: Date, dateEnd: Date){
    const mom_date_start = moment(dateStart);
    const mom_date_end = moment(dateEnd);

    return mom_date_end.diff(mom_date_start, 'minutes')

}


export function dateDiffInSeconds(dateStart: Date, dateEnd: Date){
    const mom_date_start = moment(dateStart);
    const mom_date_end = moment(dateEnd);

    return mom_date_end.diff(mom_date_start, 'seconds')

}