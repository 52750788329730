import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import IRouter from './interfaces/IRouter';
import examRoutes from './routes/exam.routes';
import Exam from './view/Private/Exam/Exam';
import Login from './view/Login/Login';
import PrivateRoutes from './view/Private/Private.index';
import Auth from './auth/auth';
import SidebarTemplate from './components/Sidebar/SidebarTemplate';
import liveRoutes from './routes/live.routes';
import AuthLive from './auth/auth.live';
import ExamSubmission from './view/Private/Exam/ExamSubmission/ExamSubmission';

interface IMainRouter {
    counter: any
}

function PrivateRouter({ children }: any) {
    const auth = Auth.checkAuth();
    return auth ? <PrivateRoutes /> : <Navigate to="/login" />;// if user is already logged in then redirect to ExamDash else redirect to login page
}

function LiveExamRouter({ children }: any) {
    const auth = AuthLive.checkAuth();
    return auth ? <PrivateRoutes /> : <Navigate to="/exam/student-info" />;// if user is already logged in then redirect to ExamDash else redirect to login page
}

export default function MainRouter({counter}: IMainRouter) {
    return (
        <>
            <Routes>
                {/*path is dashboard the render privateRounter */}
                <Route path="exam" element={<PrivateRouter />}>
                    {examRoutes.map((data: IRouter) => {
                        // data will follow poperty of IRouter
                        return (
                            <Route
                                path={data.path + "/*"}
                                element={<data.element/>}
                            />
                        )
                    })}
                </Route>

                <Route path="live" element={<LiveExamRouter />}>
                    {liveRoutes.map((data: IRouter) => {
                        // data will follow poperty of IRouter
                        return (
                            <Route
                                path={data.path + "/*"}
                                element={<data.element/>}
                            />
                        )
                    })}
                </Route>

                <Route path="/exam_submitted" element={<ExamSubmission/>} />


                <Route path="/login" element={<Login counter = {counter}/>} />
                {/* <Route path="/" element={<SidebarTemplate/>} /> */}

                <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
        </>
    )
}