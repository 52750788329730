import React, { useState, useEffect, useRef } from 'react';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import cornerstoneMath from 'cornerstone-math';
import Hammer from 'hammerjs';
import dicomParser from 'dicom-parser';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import Select from 'react-select';
import { KeyboardKey, useKeyPress } from './useKeyDown.hook';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faArrowsRotate, faBackwardStep, faCircle, faCircleDot, faCircleHalfStroke, faCross, faForwardStep, faHand, faMagnifyingGlass, faPlay, faRotate, faRuler, faSquare, faStop, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';

cornerstoneTools.external.cornerstone = cornerstone;
cornerstoneTools.external.Hammer = Hammer;
cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
cornerstoneWADOImageLoader.external.cornerstone = cornerstone;
cornerstoneWADOImageLoader.external.dicomParser = dicomParser;

interface IProps {
  onClose: () => void;
  links: any[];
  show?: boolean;
  hideTools?: boolean;
  activeToolParent?: string;
}

const CornerStoneDicomViewer = ({ onClose, links, show = true, hideTools = false, activeToolParent }: IProps) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState([]);
  const [windowWidth, setWindowWidth] = useState(400);
  const [windowCenter, setWindowCenter] = useState(40);
  const [zoom, setZoom] = useState(0.75);
  const [activeTool, setActiveTool] = useState<string>("");
  const elementRef = useRef(null);
  const [loadingImages, setLoadingImages] = useState<boolean>(false);
  const [loadcounter, setLoadcounter] = useState<number>(0);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    cornerstone.enable(element);

    cornerstoneTools.init({
      mouseEnabled: true,
      touchEnabled: true,
      globalToolSyncEnabled: false,
      showSVGCursors: true,
    });

    // cornerstoneTools.toolStyle.setToolWidth(5);
    cornerstoneTools.toolColors.setToolColor("red");

    cornerstoneTools.addTool(cornerstoneTools.PanTool);
    cornerstoneTools.addTool(cornerstoneTools.ZoomTool);
    cornerstoneTools.addTool(cornerstoneTools.WwwcTool);
    cornerstoneTools.addTool(cornerstoneTools.StackScrollMouseWheelTool);
    cornerstoneTools.addTool(cornerstoneTools.ZoomMouseWheelTool);
    cornerstoneTools.addTool(cornerstoneTools.MagnifyTool);
    cornerstoneTools.addTool(cornerstoneTools.RotateTool);
    cornerstoneTools.addTool(cornerstoneTools.LengthTool);
    cornerstoneTools.addTool(cornerstoneTools.AngleTool);
    // cornerstoneTools.addTool(cornerstoneTools.BrushTool);
    cornerstoneTools.addTool(cornerstoneTools.ProbeTool);
    cornerstoneTools.addTool(cornerstoneTools.RectangleRoiTool);
    cornerstoneTools.addTool(cornerstoneTools.EllipticalRoiTool);


    setupInitialTools();

    element.addEventListener('cornerstoneimagerendered', onImageRendered);
    return () => {
      cornerstone.disable(element);
      element.removeEventListener('cornerstoneimagerendered', onImageRendered);
    };
  }, [imagesLoaded]);

  const stack = {
    currentImageIdIndex: currentImageIndex,
    imageIds: imagesLoaded
  }

  // console.log(cornerstoneTools, "cornerstoneTools");
  useEffect(() => {
    cornerstoneTools.addStackStateManager(elementRef.current, ['stack']);
    cornerstoneTools.addToolState(elementRef.current, 'stack', stack);
  }, [imagesLoaded]);

  const setupInitialTools = () => {
    // cornerstoneTools.setToolActive('Pan', { mouseButtonMask: 3 });
    cornerstoneTools.setToolActive('Zoom', { mouseButtonMask: 2 });
    cornerstoneTools.setToolActive('StackScrollMouseWheel', { mouseButtonMask: 0 });
  };

  function handleToolChange(tool: string) {
    console.log('tool changed', tool);
    // console.log(cornerstoneTools.isToolActiveForElement(elementRef.current, tool))

    Object.keys(tools).map((key) => {
      if (key !== tool && cornerstoneTools.isToolActiveForElement(elementRef.current, key)) {
        cornerstoneTools.setToolPassive(key);
      }
    })

    // if (cornerstoneTools.isToolActiveForElement(elementRef.current, tool)) {
    //   cornerstoneTools.setToolPassive(tool);
    //   setActiveTool("");
    //   console.log('tool passive', tool);
    // } else {
    // }
    cornerstoneTools.setToolActive(tool, { mouseButtonMask: 1 });
    setActiveTool(tool);
  }

  function handleWWWcChange(option: any) {
    setWindowWidth(option?.value?.ww);
    setWindowCenter(option?.value?.wl);
    // updateViewport();
  }

  function resetViewport() {
    setZoom(1);
    setWindowWidth(128);
    setWindowCenter(128);
  }
  function handlePlayClip() {
    // console.log(cornerstoneTools.getToolState(elementRef.current, 'playClip'), 'playClip');
    // cornerstoneTools.addToolState(elementRef.current, 'playClip', {loop:false});
    cornerstoneTools.playClip(elementRef.current, 30);
  }

  function handleClear() {
    cornerstoneTools.clearToolState(elementRef.current, activeTool)
    cornerstone.updateImage(elementRef.current);
  }

  const handleScrollChange = (newIndex) => {
    setCurrentImageIndex(newIndex);
    loadAndDisplayImage(newIndex);
  };


  const handleFileChange = (event: any) => {
    const files = event.target.files;
    const imageIds: any = Array.from(files).map(file => cornerstoneWADOImageLoader.wadouri.fileManager.add(file));
    setImagesLoaded(imageIds);
    setCurrentImageIndex(0);
    loadAndDisplayImage(0);
  };

  const loadAndDisplayImage = async (index) => {
    const imageId = imagesLoaded[index];
    if (imageId) {
      await cornerstone.loadAndCacheImage(imageId)
        .then(image => {
          const zoomScale = parseInt((600 / image?.height).toFixed()) || 0.75;
          setZoom(zoomScale);
          const viewport = cornerstone.getDefaultViewportForImage(elementRef.current, image);
          viewport.voi.windowWidth = windowWidth;
          viewport.voi.windowCenter = windowCenter;
          viewport.scale = zoomScale;
          cornerstone.displayImage(elementRef.current, image, viewport);
        })
        .catch(err => console.log(err, "Error loading image"))
        .finally(() => {
          setCurrentImageIndex(index);
        })
    }
  };

  const updateViewport = () => {
    try {
      const element = elementRef.current;
      const viewport = cornerstone.getViewport(element);
      viewport.voi.windowWidth = windowWidth;
      viewport.voi.windowCenter = windowCenter;
      viewport.scale = zoom;
      cornerstone.setViewport(element, viewport);
      cornerstone.updateImage(element);
    } catch (err) {
      console.error('Unhandled exception in updateViewport()', err);
    }
  };


  function loadFileFromLink() {
    try {
      const imageIds = [];

      const promises = links.map(link =>
        fetch(link)
          .then(response => response.arrayBuffer())
          .then(buffer => {
            const file = new Blob([buffer], { type: 'application/dicom' });
            const imageId = cornerstoneWADOImageLoader.wadouri.fileManager.add(file);
            imageIds.push(imageId);
            setLoadcounter((prev) => prev + 1);
          })
          .catch(err => {
            console.error(err, "error while loading image from link");
          })
      );

      setLoadingImages(true);
      Promise.all(promises)
        .then(() => {
          setImagesLoaded(imageIds);
          setCurrentImageIndex(0);
          loadAndDisplayImage(0);
        })
        .catch(err => {
          console.error(err, "error while loading images from links");
        })
        .finally(() => {
          setLoadingImages(false);
          setLoadcounter(0);
        })
    } catch (err) {
      console.error(err, "error while loading images from links");
    }
  }

  const onImageRendered = (event) => {
    // console.log('onImageRendered', event.detail);
    // console.log('currntimageindex' , currentImageIndex);
    // console.log('imagesloaded' , imagesLoaded);
    const eventData = event.detail;
    setWindowWidth(Math.round(eventData?.viewport?.voi?.windowWidth));
    setWindowCenter(Math.round(eventData?.viewport?.voi?.windowCenter));
    setCurrentImageIndex(imagesLoaded.indexOf(eventData.image.imageId));
  };

  useEffect(() => {
    updateViewport();
  }, [windowWidth, windowCenter, zoom]);

  useEffect(() => {
    if (imagesLoaded.length > 0) {
      setCurrentImageIndex(0);
      loadAndDisplayImage(0);
    }
  }, [imagesLoaded]);

  useEffect(() => {
    if (show && links.length > 0) {
      loadFileFromLink();
    }
  }, [show, links]);

  useEffect(() => {
    if (activeToolParent) {
      handleToolChange(activeToolParent);
    }
  }, [activeToolParent])

  const handleKeyPress = (pressedKey?: KeyboardKey) => {
    if (pressedKey) {
      handleToolChange(pressedKey);
    }
  };
  useKeyPress(handleKeyPress, [KeyboardKey.P, KeyboardKey.L, KeyboardKey.W, KeyboardKey.M, KeyboardKey.A, KeyboardKey.R, KeyboardKey.E, KeyboardKey.T, KeyboardKey.D]);

  return (
    <div className="mt-2">
      {
        !loadingImages ?
          <>
            <Button variant='warning' className='mb-2' onClick={onClose}>{'< Back'}</Button>
            {/* <div className='d-flex align-items-center justify-content-center'>
              <Row className='w-100'>

                {
                  !hideTools &&
                  Object.keys(tools).map((key) => {
                    return (
                      <Col md={2}>
                        <Button key={key} size='sm' className='mb-2' variant={activeTool === key ? 'danger' : 'primary'} onClick={() => handleToolChange(key)}>{key}</Button>
                      </Col>
                    )
                  })
                }
                {
                  !hideTools &&
                  <Col md={2}>
                    <Button size='sm' variant='primary' className='mb-2 text-nowrap' onClick={handleClear}>Clear Annotations</Button>
                  </Col>
                }
                <Col md={2}>
                  <Button size="sm" variant='primary' className='mb-2' onClick={() => handlePlayClip()}>Play Clip</Button>
                </Col>
                <Col md={2}>
                  <Button size='sm' variant='primary' onClick={() => cornerstoneTools.stopClip(elementRef.current)}>Stop Clip</Button>
                </Col>
              </Row>
            </div> */}
            {
              !hideTools &&
              <>
                <Row className='mt-2'>
                  <Col md={3}>
                    <Select
                      options={toolsOptions}
                      onChange={(option): any => handleToolChange(option.value)}
                      value={toolsOptions.find((option) => option.value === activeTool) || null}
                      placeholder="Select Tool"
                    />
                  </Col>
                  <Col md={3}>
                    <Select
                      options={wwwcOptions}
                      onChange={(option): any => handleWWWcChange(option)}
                      // value={toolsOptions.find((option) => option.value === activeTool) || null}
                      placeholder="Select Additional Tool"
                    />
                  </Col>
                </Row>
              </>
            }
            <Row className='mt-3'>
              <Col className='d-flex align-items-center flex-wrap gap-3'>
                {
                  !hideTools &&
                  <>
                    <Button variant='warning' className=' text-nowrap' onClick={handleClear}> <FontAwesomeIcon icon={faXmarkCircle} /> Clear Annotations</Button>
                    <Button variant='warning' className=' text-nowrap' onClick={resetViewport}><FontAwesomeIcon icon={faArrowsRotate} /> Reset</Button>
                  </>
                }
                <Button variant='warning' className='' onClick={() => handlePlayClip()}><FontAwesomeIcon icon={faPlay} /> Play Clip</Button>
                <Button variant='warning' onClick={() => cornerstoneTools.stopClip(elementRef.current)}><FontAwesomeIcon icon={faStop} /> Stop Clip</Button>
                <Button variant='warning' onClick={() => loadAndDisplayImage((currentImageIndex - 1 > 1 ? currentImageIndex - 1 : imagesLoaded.length - 1) % imagesLoaded.length)}><FontAwesomeIcon icon={faBackwardStep} /> Previous Image</Button>
                <Button variant='warning' onClick={() => loadAndDisplayImage((currentImageIndex + 1) % imagesLoaded.length)}> <FontAwesomeIcon icon={faForwardStep} /> Next Image</Button>
              </Col>
            </Row>
            <div className="d-flex align-items-center justify-content-center mt-2 text-white">
              {/* Window Width: {windowWidth} <br />Window Center: {windowCenter} 
              <br /> */}
              <span className='fs-6 text-light'>
                Current Image: {currentImageIndex + 1} / {imagesLoaded.length}
              </span>
            </div>
            {/* <input type="file" onChange={handleFileChange} multiple accept=".dcm" /> */}
            <div className="" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
              <div
                ref={elementRef}
                // style={{ minWidth: '600px', height: '600px', position: 'relative', color: 'white', border: '5px solid red', userSelect: 'none' }}
                style={{ width: '600px', height: '600px', position: 'relative', color: 'white', border: '5px solid #ffc107', userSelect: 'none' }}
                // style={{ minWidth: '400px', minHeight: '400px', position: 'relative', color: 'white', border: '1px solid black', userSelect: 'none' }}
                onContextMenu={(e) => e.preventDefault()}
                className=''
              ></div>
            </div>
            <div className="text-center text-primary">
              Window Width: {windowWidth} <br />Window Center: {windowCenter}
            </div>
            <div className="p-2 mt-2 d-flex align-items-center justify-content-center">
              <Form.Range
                min={0}
                max={imagesLoaded.length - 1}
                value={currentImageIndex}
                onChange={(e) => loadAndDisplayImage(e.target.value)}
                className='w-50 p-2 rouded custom-range'
              />
            </div>
            {/* <div className="d-flex align-items-center justify-content-center mt-2 gap-2">
              </div> */}
            <div className="d-flex align-items-center justify-content-center mt-2 text-white">
              <span className='fs-6 text-danger'>
                Current Image: {currentImageIndex + 1} / {imagesLoaded.length}
              </span>
            </div>
          </>
          :
          <>
            <div className="d-flex align-items-center justify-content-center h-100">
              <Spinner animation={'border'} variant='primary' />
              <span className='fs-1 ms-2 text-primary'>Loading Images {loadcounter} / {links.length} </span>
            </div>
          </>
      }
    </div>
  );
};

export default CornerStoneDicomViewer;

export const tools = {
  "Wwwc": "Wwwc",
  "Pan": "Pan",
  'Magnify': 'Magnify',
  "Length": "Length",
  "Angle": "Angle",
  // "Brush": "Brush",
  "Probe": "Probe",
  "RectangleRoi": "RectangleRoi",
  "EllipticalRoi": "EllipticalRoi",
  "Rotate": "Rotate",
}

export const toolsOptions = [
  {
    value: 'Pan',
    label: <span className=''> <FontAwesomeIcon icon={faHand} /> Pan (p)</span>
  },
  {
    value: 'Wwwc',
    label: <span className=''> <FontAwesomeIcon icon={faCircleHalfStroke} /> Wwwc (w)</span>
  },
  {
    value: 'Magnify',
    label: <span className=''> <FontAwesomeIcon icon={faMagnifyingGlass} /> Magnify (m)</span>
  },
  {
    value: 'Length',
    label: <span className=''> <FontAwesomeIcon icon={faRuler} /> Length (l)</span>
  },
  {
    value: 'Angle',
    label: <span className=''> <FontAwesomeIcon icon={faAngleLeft} /> Angle (a)</span>
  },
  {
    value: 'Probe',
    label: <span> <FontAwesomeIcon icon={faCircleDot} /> Probe (d)</span>
  },
  {
    value: 'RectangleRoi',
    label: <span> <FontAwesomeIcon icon={faSquare} /> RectangleRoi (r)</span>
  },
  {
    value: 'EllipticalRoi',
    label: <span> <FontAwesomeIcon icon={faCircle} /> EllipticalRoi (e)</span>
  },
  {
    value: 'Rotate',
    label: <span> <FontAwesomeIcon icon={faRotate} /> Rotate (t)</span>
  }
];

const wwwcOptions = [
  {
    label: 'Brain',
    value: {
      ww: 80,
      wl: 40
    }
  },
  {
    label: 'Stroke',
    value: {
      ww: 30,
      wl: 30
    }
  },
  {
    label: 'Mediastinal',
    value: {
      ww: 400,
      wl: 40
    }
  },
  {
    label: 'Lung',
    value: {
      ww: 1500,
      wl: -500
    }
  },
  {
    label: 'Bone',
    value: {
      ww: 3000,
      wl: 500
    }
  },
  {
    label: 'Soft Tissue',
    value: {
      ww: 350,
      wl: 50
    }
  }
];
