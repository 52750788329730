import React, { useContext, useEffect } from 'react';
import RenderOption from '../../../../../components/Options/RenderOption';
import { SelectedQuestion } from '../../context';

interface IExamQuestionOptions {
    options: any,
    onChange: any,
    userAnswers: IOptionsData[] | undefined
}


export interface IOptionsData {
    optionId: string,
    value: any,
    flag: boolean,
    child?: any,
    boolean?: boolean
    truefalsewithdontknow?: boolean
}


export default function ExamQuestionOptions(props: IExamQuestionOptions) {

    const onOptionChange = (e: any, type: "choice" | "choicewithtext" | "text" | "boolean" | "truefalsewithdontknow") => {
        const current_options = props.userAnswers;
        if (type === "text" || type === "boolean" || type == 'truefalsewithdontknow') {
            if (current_options && current_options.length > 0) {
                let other_options = current_options.filter((options: IOptionsData) => options.optionId != e.optionId);

                if (other_options && other_options.length > 0) {
                    const new_options = [...other_options, e]
                    props.onChange(new_options)
                }
                else{
                    props.onChange([e])
                }
            }
            else {
                props.onChange([e])
            }
        }


        if (type === "choice" || type === "choicewithtext" ) {
            if (e.value === true) {
                let other_options = current_options?.filter((options: IOptionsData) => options.optionId != e.optionId);
                if (other_options && other_options.length > 0) {
                    other_options = other_options.map((data: any) => {
                        return { ...data, value: false }
                    })
                    props.onChange([...other_options, e])
                }
                else {
                    props.onChange([e]);
                }
            }
            else {
                let other_options = current_options?.filter((options: IOptionsData) => options.optionId != e.optionId);

                if (other_options && other_options.length > 0) {
                    other_options = other_options.map((data: any) => {
                        return { ...data }
                    })
                    props.onChange([...other_options, e])
                }
                else {
                    props.onChange([e]);
                }
            }

        }
    }

    return (<div className='m-3'>

        {props.options && props.options.length > 0 &&
            props.options.map((option: any, index: number) => {
                return (<>
                    <RenderOption
                        choice={option.choice}
                        value={option.value}
                        optionId={option._id}
                        onChange={onOptionChange}
                        index={index + 1}
                        boolean={option.boolean}
                        defaultValue={props.userAnswers?.find((answers: any) => answers.optionId === option._id)}
                        truefalsewithdontknow={option.truefalsewithdontknow}
                    />
                </>
                )
            })
        }

    </div>)
}
