import makeRequestPublic, { makeBasicCheckServer } from "../api/makeRequest.public";
import { RequstMethods } from "../api/requestMethode";
import url from "../api/urls";

export default class PublicService {
    static async getAllLiveExams() {
        return await makeRequestPublic(url.public.getAllLiveExams, RequstMethods.GET);
    }

    static async getQuestionDataByExamId(examId: string) {
        return await makeRequestPublic(url.public.getQuestionDataByExamId + "/" + examId, RequstMethods.GET);
    }

    static async checkLocalServer() {
        return await makeBasicCheckServer(url.server.checkLocalServer, RequstMethods.GET)
    }

    static async setLocalServer(payload: any) {
        return await makeBasicCheckServer("/", RequstMethods.POST, payload)
    }

}