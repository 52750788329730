import { faFlag, faFontAwesomeFlag } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { SelectedQuestion } from '../../view/Private/Exam/context';


interface IChoiceOption {
    choiceLabel: any
    count: number
    onChange: any
    optionId: string
    defaultValue?: any
    boolean?: any
}

export default function ChoiceBooleanOption(props: IChoiceOption) {
    
    const { selectedQuestionId, setSelectedQuestionId } = useContext<any>(SelectedQuestion);


    const [isSelected, setSelected] = useState(props.defaultValue && props.defaultValue.value);

    useEffect(() => {
        props.onChange({
            optionId: props.optionId,
            value: isSelected
        });
    }, [isSelected, selectedQuestionId]);

    const handleChange = (e: any, value: string) => {
        if(isSelected == value) { 
            setSelected(undefined);
        }
        else{
            setSelected(value)
        }
    }


    useEffect(()=>{
        if(props.defaultValue && props.defaultValue.value === "true"){
            setSelected("true")
        }
        else if (props.defaultValue && props.defaultValue.value === "false"){
            setSelected("false")
        }
        else{
            setSelected(undefined)
        }

    },[props.defaultValue])


    return (
        <div className="mb-2">
            <span className='fw-bold fs-6 mb-2'> {String((props.count)+ ".")} {props.choiceLabel}</span>
            {/* <span className='fw-bold fs-6 mb-2'> {String((props.count + 9).toString(36).toUpperCase() + ".")} {props.choiceLabel}</span> */}
            <Form.Group>
                <Form.Check
                    onClick={(e: any) => handleChange(e, "true")}
                    // checked={isSelected}
                    type="radio"
                    checked={isSelected == "true" ? true : false}
                    label={<div className="h5 d-flex justify-content-end align-items-center">
                        {"True"}
                    </div>}
                />
                <Form.Check
                    onClick={(e: any) => handleChange(e, "false")}
                    // checked={isSelected}
                    type="radio"
                    checked={isSelected == "false" ? true : false}
                    label={<div className="h5 d-flex justify-content-end align-items-center">
                       {"False"}
                    </div>}
                />
            </Form.Group>
        </div>
    )
}