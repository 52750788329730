import makeRequest from "../api/makeRequest";
import url from "../api/urls";
import { RequstMethods } from "../api/requestMethode";

export class AuthService {
    static async login(inputPayload: any) {
        return await makeRequest(url.login , RequstMethods.POST ,inputPayload )
    }

    static async logout(inputPayload: any) {
        return await makeRequest(url.logout , RequstMethods.PUT ,inputPayload )
    }
}