import React from "react";
import { Badge } from "react-bootstrap";

interface IBooleanLabel {
    value: boolean
    label: string
}

export default function BooleanLabel(props: IBooleanLabel){
    return (
        <Badge bg={props.value ? "success" : "danger"}>
            {props.label}
        </Badge>
    )
}