import { faGraduationCap, faInfo } from "@fortawesome/free-solid-svg-icons";
import IRouter from "../interfaces/IRouter";
import Exam from "../view/Private/Exam/Exam";
import StudentIndex from "../view/Private/Exam/StudentInfo/Student.index";
import ExamDashboard from "../view/Private/ExamDashboard/ExamDashboard";
import Instructions from "../view/Private/Instructions/Instructions.index";
import StartExam from "../view/Private/StartExam/StartExam.index";

export const examBaseUrl = "/exam";

const examRoutes:IRouter[] = [
    // {
    //    add another route examselection
    // }
    {
        path: "student-info",
        navbarShow: false,
        element: StudentIndex,
        name: "Student-Info",
        icon: faInfo
    },
    {
        path: "instructions",
        navbarShow: false,
        element: Instructions,
        name: "Instructions",
        icon: faInfo
    },
    {
        path: "start",
        navbarShow: false,
        element: StartExam,
        name: "Start",
        icon: faInfo
    },
    {
        path: "exam-dashboard",
        navbarShow: false,
        element: ExamDashboard,
        name: "exam-dashboard",
        icon: faInfo
    }
]

export default examRoutes;