import makeRequest from "../api/makeRequest";
import makeRequestLive from "../api/makeRequest.live";
import { RequstMethods } from "../api/requestMethode";
import url from "../api/urls";

export default class LiveExam {
    static async generateTokenForLiveExam(examId: string, examMappingId: string){
        return await makeRequest(url.liveExam.getLiveExamToken, RequstMethods.POST, { 
            examId, examMappingId
        })
    }

    static async getExamDetails(){
        return await makeRequestLive(url.liveExam.getExamDetails +`?timestamp=${new Date().getTime()}`, RequstMethods.GET);
    }

    static async getExamData(){
        return await makeRequestLive(url.liveExam.getExamData +`?timestamp=${new Date().getTime()}`, RequstMethods.GET);
    }

    static async getResponses(){
        return await makeRequestLive(url.liveExam.getResponses +`?timestamp=${new Date().getTime()}`, RequstMethods.GET);
    }

    static async getQuestionData(dataId: string){
        return await makeRequestLive(url.liveExam.getQuestionData+ "/" + dataId +"?timestamp="+new Date().getTime(), RequstMethods.GET);
    }

    static async getS3url(){
        return await makeRequestLive(url.liveExam.getS3url, RequstMethods.GET);
    }

    static async  getFileData(s3Url:string) {
        return makeRequestLive(s3Url , RequstMethods.GET)
    }

    static async getUserInfo(){
        return makeRequestLive(url.liveExam.getUserInfo +`?timestamp=${new Date().getTime()}`, RequstMethods.GET);
    }

    static async markForReview(questionId: any, examMappingId: any){
        return makeRequestLive(url.liveExam.markForReview + "/"+ questionId, RequstMethods.PUT, examMappingId);
    }

    static async clearResponse(questionId: any){
        return makeRequestLive(url.liveExam.clearResponse + "/"+ questionId, RequstMethods.PUT);
    }

    static async getMarkedForReview(questionId: any){
        return makeRequestLive(url.liveExam.markForReview + "/"+ questionId, RequstMethods.GET);
    }

    static async updatedResponseData(userResponses: any) {
        return makeRequestLive(url.liveExam.updatedResponseData+`?timestamp=${new Date().getTime()}`, RequstMethods.PUT, userResponses);
    }

    static async getUserSavedAnswerByQuestionId(questionId: any) {
        return makeRequestLive(url.liveExam.getUserSavedAnswersByQuestionId+ "/" + questionId +`?timestamp=${new Date().getTime()}`, RequstMethods.GET);
    }

    static async setExamStatusCompleted() {
        return makeRequestLive(url.liveExam.setExamStatusCompleted, RequstMethods.POST ,{});
    }

    static async getAllUserEncryptedOptions(){
        return makeRequestLive(url.liveExam.getAllUserEncryptedOptions +`?timestamp=${new Date().getTime()}`, RequstMethods.GET)
    }

    static async getInstructions(){
        return makeRequestLive(url.liveExam.getInstructions +`?timestamp=${new Date().getTime()}`, RequstMethods.GET);
    }

    static async checkUserRemainingTime(user_time: number){
        return makeRequestLive(url.liveExam.checkUserRemainingTime +`?timestamp=${new Date().getTime()}`, RequstMethods.PUT, {user_time})
    }

    static async getQuestionThumbnails(questionId: any){
        return makeRequestLive(url.liveExam.getQuestionThumbnails + "/" + questionId +`?timestamp=${new Date().getTime()}`, RequstMethods.GET);
    }
    
}